.ant-list-pagination .ant-pagination {
	.ant-pagination-total-text {
		margin-right: auto;
        padding-left: 20px;
	}

	.ant-pagination-options .ant-select-selector {
		border: none;
		justify-self: start;
		.ant-select-selection-item {
			font-weight: 700;
			color: var(--primary);
		}
	}
}