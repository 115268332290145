.added {
	color: var(--success);
}

.removed {
	text-decoration: line-through;
}

.title {
	font-weight: 700;
}

.subtitle {
	color: var(--gray-icon);
}

.nameCell {
	width: 150px;
	text-overflow: ellipsis;
}

.iconCell {
	width: 20px;
}

.resultCell {
	flex: 1;
}

.container {
	padding: 12px;
	width: 100%;
	border-radius: 6px;
	box-shadow: 0 0 10px 0 rgba(184, 184, 184, 0.25);
}
