@import "shared/styles/vars";

.badge {
  width: 102px;
  height: 32px;
  padding: 0 8px;
  border-radius: 30px;
  font-weight: 500;

  &.active {
    background: change-color($success, $alpha: 0.12);
  }

  &.inactive {
    background: change-color($error, $alpha: 0.12);
  }
}