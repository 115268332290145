.ant-select > .ant-select-selector {
	min-height: 34px;
	background-color: transparent !important;
	border-color: transparent !important;

	&:hover {
		border-color: transparent !important;
	}
	&:active {
		border-color: transparent !important;
	}
	&:focus {
		border-color: transparent !important;
		outline: none !important;
		box-shadow: none !important;
	}

	.ant-select-focused .ant-select-open {
		&:hover {
			border-color: transparent !important;
		}
		&:active {
			border-color: transparent !important;
		}
		&:focus {
			border-color: transparent !important;
			outline: none !important;
			box-shadow: none !important;
		}
	}
}

.ant-select-clear {
	svg {
		display: none;
	}
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.71929 0.280712C1.53069 0.0985537 1.27808 -0.00224062 1.01589 3.78026e-05C0.75369 0.00231622 0.502878 0.107486 0.31747 0.292894C0.132062 0.478302 0.0268924 0.729115 0.024614 0.991312C0.0223356 1.25351 0.12313 1.50611 0.305288 1.69471L3.59829 4.98771L0.305288 8.28071C0.209778 8.37296 0.133596 8.4833 0.0811869 8.60531C0.0287779 8.72731 0.00119157 8.85853 3.7757e-05 8.99131C-0.00111606 9.12409 0.0241855 9.25577 0.0744664 9.37866C0.124747 9.50156 0.199 9.61321 0.292893 9.70711C0.386786 9.801 0.498438 9.87525 0.621334 9.92553C0.74423 9.97581 0.87591 10.0011 1.00869 9.99996C1.14147 9.99881 1.27269 9.97122 1.39469 9.91881C1.5167 9.8664 1.62704 9.79022 1.71929 9.69471L5.01229 6.40171L8.30529 9.69471C8.49389 9.87687 8.74649 9.97766 9.00869 9.97539C9.27089 9.97311 9.5217 9.86794 9.70711 9.68253C9.89252 9.49712 9.99768 9.24631 9.99996 8.98411C10.0022 8.72192 9.90145 8.46931 9.71929 8.28071L6.42629 4.98771L9.71929 1.69471C9.90145 1.50611 10.0022 1.25351 9.99996 0.991312C9.99768 0.729115 9.89252 0.478302 9.70711 0.292894C9.5217 0.107486 9.27089 0.00231622 9.00869 3.78026e-05C8.74649 -0.00224062 8.49389 0.0985537 8.30529 0.280712L5.01229 3.57371L1.71929 0.280712Z" fill-opacity="0.4"/></svg>');
	width: 10px;
	height: 10px;
	color: var(--gray-icon) !important;
}

.ant-select-arrow > span {
	svg {
		display: none !important;
	}
	background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2014%208%22%20fill%3D%22none%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0.292893%200.296069C0.683417%20-0.0986891%201.31658%20-0.0986891%201.70711%200.296069L7%205.55962L12.2929%200.296068C12.6834%20-0.0986896%2013.3166%20-0.0986897%2013.7071%200.296068C14.0976%200.690826%2014.0976%201.33085%2013.7071%201.72561L7.70711%207.70393C7.31658%208.09869%206.68342%208.09869%206.29289%207.70393L0.292893%201.72561C-0.0976314%201.33085%20-0.0976314%200.690826%200.292893%200.296069Z%22%20fill%3D%22%23151D34%22%20fill-opacity%3D%220.4%22%2F%3E%3C%2Fsvg%3E');
	width: 10px;
	height: 10px;
	background-size: cover;
	display: inline-block;
	vertical-align: middle;
	color: var(--gray-icon) !important;
}

.ant-select-filled > .ant-select-selector {
	background: var(--light-gray);

	&:hover {
		border-color: var(--primary-border-faded);
	}

	&:active {
		background: transparent !important;
		border-color: var(--primary-border-faded);
	}
}

.ant-input-affix-wrapper .ant-input-clear-icon {
	color: var(--gray-icon) !important;
}

.ant-pagination-options .ant-select-selector {
	min-height: unset;
}
