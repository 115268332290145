.ant-table-wrapper .ant-pagination {
	.ant-pagination-total-text {
		margin-right: auto;
	}

	.ant-pagination-options .ant-select-selector {
		border: none;
		justify-self: start;
		.ant-select-selection-item {
			font-weight: 700;
			color: var(--primary);
		}
	}
}

.ant-table-column-sorter-inner {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy43MDcxMSAzLjIzMzE3QzguMDk3NjMgMy42MDM5NSA4LjA5NzYzIDQuMjA1MTEgNy43MDcxMSA0LjU3NTg5QzcuMzE2NTggNC45NDY2OCA2LjY4MzQyIDQuOTQ2NjggNi4yOTI4OSA0LjU3NTg5TDQgMi4zOTg5TDEuNzA3MTEgNC41NzU4OUMxLjMxNjU4IDQuOTQ2NjggMC42ODM0MTggNC45NDY2OCAwLjI5Mjg5MyA0LjU3NTg5Qy0wLjA5NzYzMDggNC4yMDUxMSAtMC4wOTc2MzA4IDMuNjAzOTUgMC4yOTI4OTQgMy4yMzMxN0wyLjU4NTc5IDEuMDU2MThDMy4zNjY4NCAwLjMxNDYwOCA0LjYzMzE3IDAuMzE0NjA4IDUuNDE0MjEgMS4wNTYxOEw3LjcwNzExIDMuMjMzMTdaIiBmaWxsPSIjMTUxRDM0IiBmaWxsLW9wYWNpdHk9IjAuNjUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjI5Mjg5MyA4Ljc2NjgzQy0wLjA5NzYzMTEgOC4zOTYwNSAtMC4wOTc2MzExIDcuNzk0ODkgMC4yOTI4OTMgNy40MjQxMUMwLjY4MzQxOCA3LjA1MzMyIDEuMzE2NTggNy4wNTMzMiAxLjcwNzExIDcuNDI0MTFMNCA5LjYwMTFMNi4yOTI4OSA3LjQyNDExQzYuNjgzNDIgNy4wNTMzMiA3LjMxNjU4IDcuMDUzMzIgNy43MDcxMSA3LjQyNDExQzguMDk3NjMgNy43OTQ4OSA4LjA5NzYzIDguMzk2MDUgNy43MDcxMSA4Ljc2NjgzTDUuNDE0MjEgMTAuOTQzOEM0LjYzMzE2IDExLjY4NTQgMy4zNjY4MyAxMS42ODU0IDIuNTg1NzkgMTAuOTQzOEwwLjI5Mjg5MyA4Ljc2NjgzWiIgZmlsbD0iIzE1MUQzNCIgZmlsbC1vcGFjaXR5PSIwLjY1Ii8+Cjwvc3ZnPgo=');

	width: 8px;
	height: 12px;
	color: var(--gray-icon);
	span {
		display: none;
	}
}

// custom scrollbar
.ant-table-body::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.ant-table-body::-webkit-scrollbar-track {
	background-color: transparent;
}

.ant-table-body::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: var(--bg-subsider-hover);

	&:hover {
		background-color: var(--gray-table-border);
	}
}

.ant-table-wrapper .ant-table-thead .ant-table-cell,
.ant-table-body .ant-table-row .ant-table-cell {
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: normal;
}

.ant-table-body .ant-table-row .ant-table-cell {
	max-width: 500px;
	min-width: 50px;
}

.ant-table-wrapper .ant-table-thead .ant-table-cell{
	white-space: nowrap;
}
