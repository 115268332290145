.componentContainer {
	display: flex;
	flex-direction: column;
	row-gap: 4px;

	.dropdownSelect {
		display: flex;
		align-items: center;
		background-color: var(--light-gray);
		border-radius: 6px;
		gap: 8px;
		border: 1px solid var(--light-gray);

		&:hover {
			border: 1px solid var(--primary-border-faded);
		}

		&:focus {
			background: transparent !important;
			border-color: var(--primary-border-faded);
		}

		&.focused {
			background-color: white !important;
			border-color: var(--primary-border-faded);
		}

		.iconContainer {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}

	.select {
		width: 100%;

		.ant-select.ant-select-filled > .ant-select-selector {
			background: transparent !important;
			border-color: transparent !important;

			&:hover {
				border-color: transparent !important;
			}
			&:active {
				border-color: transparent !important;
			}
			&:focus {
				border-color: transparent !important;
				outline: none !important;
				box-shadow: none !important;
			}
		}
	}

	.additionalPadding {
		padding-right: 10px;
	}

	.error {
		border: 1px solid var(--error);
		background-color: var(--error-faded);

		&:hover {
			border: 1px solid var(--error);
		}
	}

	.errorText {
		color: var(--error);
	}
}
