@import '../vars';

:root {
	--primary: #{$primary};
	--primary-border-faded: #{change-color($primary, $alpha: 0.4)};

	--text: #{$text};
	--text-faded: #{change-color($text, $alpha: 0.65)};
	--gray: #{change-color($text, $alpha: 0.24)};
	--gray-icon: #{change-color($text, $alpha: 0.4)};

	--success: #{$success};

	--error: #{$error};
	--error-faded: #{change-color($error, $alpha: 0.05)};
	--input-error: #{change-color($error, $alpha: 0.05)};

	--warning: #{$warning};
	--tab-underline: #{change-color($warning, $alpha: 1)};

	--white-base: #{$white};
	--svg-white: #{change-color($white, $alpha: 1)};

	--disabled: #{$disabled};
	--gray-table-border: #{change-color($disabled, $alpha: 1)};
	--light-gray: #{change-color($disabled, $alpha: 0.4)};
	--bg-table: #{change-color($disabled, $alpha: 0.2)};

	--inactive-button: #c5c5c5;
	--bg-subsider-hover: rgb(240, 240, 240);

	--aside-width: 55px;
}
