:global(body.has-detail-footer) {
	--detail-footer-height: 56px;
}

.detailFooter {
	position: fixed;
	bottom: 0;
	right: 0;
	left: var(--aside-width);
	height: var(--detail-footer-height);
	border-top: 1px solid var(--gray-table-border);
	background: var(--white-base);
	padding: 12px 24px;
	transition: left 0.3s ease;
}
