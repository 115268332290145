.ant-card-body {
	padding: 16px !important;
}

.ant-card-contain-grid {
	border-radius: 8px;
}

.ant-card .ant-card-grid {
	padding: 0;
}

.ant-card-bordered {
	border-color: rgba(224, 224, 224, 1);
}
