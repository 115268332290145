.mainLayout {
	height: 100vh;
}

.content {
	padding: 48px 24px 12px 24px;
	position: relative;
	background-color: var(--white-base);
	overflow: auto;
	flex: 1;

	:global(.has-detail-footer) & {
		padding-bottom: calc(var(--detail-footer-height) + 12px);
	}
}

.breadCrumbs {
	position: absolute;
	top: 18px;
	transition: margin-left 0.3s ease;
}

.slideLeft:not(.subsider) {
	margin-left: 340px;
	transition: margin 0.3s ease;
}
